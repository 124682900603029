import React, { useState, useEffect } from 'react';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './About.scss';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "frAbouts" && title == "Yassin D."]';
    
    client.fetch(query)
      .then((data) => setAbouts(data))
  }, []);

  return (
    <>
      <h2 className="head-text">À propos</h2>
      <div className="app__about-container">
        <div className="app__about-presentation">
          <div className="app__about-badge">
            <div className="badge-cmp app__flex">
              <div>
                <h1>{abouts[0]?.title}</h1>
              </div>
            </div>
            <div className="tag-cmp app__flex">
              <p className="p-text">Ingénieur Consultant</p>
            </div>
          </div>
          <div className="app__about-photo app__flex">
            <div className="app__flex">
              <img src={abouts[0] !== undefined ? urlFor(abouts[0]?.photo) : null} alt="profile pic" />
            </div>
          </div>
        </div>
        <div className="app__about-details">
          <p>{abouts[0]?.desc}</p>
          <p>{abouts[0]?.desc2}</p>
          <p>{abouts[0]?.desc3}</p>
          <p>{abouts[0]?.desc4}</p>
        </div>
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);