import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Certifications.scss';

const Certifications = () => {
  const [certDetail, setCertDetail] = useState([]);
  const [certs, setCerts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateContent, setAnimateContent] = useState({ opacity: 1 });

  const handleClick = (index) => {
    setAnimateContent([{ opacity: 0 }]);

    setTimeout(() => {
      setCurrentIndex(index);
      setAnimateContent([{ opacity: 1 }])
    }, 500);
  }

  useEffect(() => {
    const detailQuery = '*[_type == "frCertDetails"] | order(order desc)';
    const certQuery = '*[_type == "certifications"] | order(order desc)';

    client.fetch(detailQuery)
      .then((data => {
        setCertDetail(data);
      }))

    client.fetch(certQuery)
      .then((data => {
        setCerts(data);
      }))
  }, []);

  const certIndex = certDetail[currentIndex];

  return (
    <>
      <h2 className="head-text">Certifications</h2>
      {certDetail.length && (
        <>
          <div className="app__certification-item app__flex">
            <motion.img
              animate={animateContent}
              transition={{ duration: 0.5 }}
              src={urlFor(certIndex.imgurl)}
              alt="certification"
            />
            <motion.div className="app__certification-content"
              animate={animateContent}
              transition={{ duration: 0.5 }}
            >
              <div className="app__certification-content-header">
                <h4 className="bold-text">{certIndex.name}</h4>
                <h5 className="p-text">{certIndex.company}</h5>
                <h6 className="bold-text">Date de validation: {certIndex.validationdate}</h6>
              </div>
              <p className="p-text">{certIndex.description}</p>
              <a className="bold-text" href={`${certIndex.credlyurl}`} target="_blank" rel="noreferrer noopener">Verifier sur Credly</a>
            </motion.div>
          </div>
        </>
      )}
      <div className="app__certification-certs app__flex">
        {certs.map((cert, index) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={cert._id}
          >
            <img
              src={urlFor(cert.imgUrl)}
              alt={cert.name}
              onClick={() => handleClick(index)}
            />
          </motion.div>
        ))}
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Certifications, 'app__certification'),
  'certifications',
  'app__primarybg',
);