import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <nav className="app__navbar">
      <a href="/">
        <div className="app__navbar-logo">
          <img src={images.yassin} alt="Logo" />
        </div>
      </a>
      <ul className="app__navbar-links">
        {['home', 'about', 'work', 'skills', 'certifications', 'contact'].map((item, index) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>
              {['Accueil', 'À Propos', 'Projets', 'Compétences', 'Certifications', 'Contact'][index]}
            </a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-lang">
          <p>
            <a href="/" className="app__navbar-lang-active">FRA</a> | <a href="https://yassindpro.com">ENG</a>
          </p>
      </div>
      <div className="app__navbar-menu">
          <HiMenuAlt4 onClick={() => setToggle(true)} />
          {toggle && (
            <motion.div
              whileInView={{ x: [300, 0] }}
              transition={{ duration: 0.85, ease: 'easeOut' }}
            >
              <HiX onClick={() => setToggle(false)} />
              <ul>
                {['home', 'about', 'work', 'skills', 'certifications', 'contact'].map((item, index) => (
                  <li key={item}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {['Accueil', 'À Propos', 'Projets', 'Compétences', 'Certifications', 'Contact'][index]}
                    </a>
                  </li>
                ))}
              </ul>
            </motion.div>
           )}
      </div>
    </nav>
  );
};

export default Navbar;