import React, {useState } from 'react'

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: ''});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message,
    }

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
  }

  return (
    <>
      <h2 className="head-text app__footer-title">Me Contacter</h2>
      <p className="app__footer-text">N'hésitez pas à m'envoyer un mail</p>
      <div className="app__footer-cards">
      <a href="mailto:yassindpro@outlook.com" className="app__footer-email"><div className="app__footer-card">
          <img src={images.email} alt="email" />
          <p className="p-text">yassindpro@outlook.com</p>
        </div></a>
        {/* <div className="app__footer-card">
          <img src={images.mobile} alt="mobile" />
          <a href="tel: +33 45 67 89 10" className="p-text">+33 45 67 89 10</a>
        </div> */}
      </div>

    {!isFormSubmitted ?
      <div className="app__footer-form app__flex">
        <p className="app__footer-text">Ou bien à remplir le formulaire ci-dessous</p>
        <div className="app__flex">
          <input className="p-text" type="text" placeholder="Votre Nom" name="name" value={name} onChange={handleChangeInput} />
        </div>
        <div className="app__flex">
          <input className="p-text" type="email" placeholder="Votre Email" name="email" value={email} onChange={handleChangeInput} />
        </div>
        <div>
          <textarea
            className="p-text"
            placeholder="Votre Message"
            name="message"
            value={message}
            onChange={handleChangeInput}
          />
        </div>
        <button type="button" className="p-text" onClick={handleSubmit}>{loading ? 'Sending' : 'Envoyer'}</button>
      </div>
    : <div className="app__footer-submitted">
        <h3 className="head-text">👍 Merci de m'Avoir contacté</h3>
      </div>
    }
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);