import React from 'react';
import  { BsGithub } from 'react-icons/bs';


const SocialMedia = () => {
  return (
    <div className="app__social">
        <a href="https://github.com/ydamni" target="_blank" rel="noreferrer noopener">
          <div>
              <BsGithub />
          </div>
        </a>
        {/* <a href="https://linkedin.com/in/">
          <div>
              <BsLinkedin />
          </div>
        </a> */}
    </div>
  )
}

export default SocialMedia