import React from 'react'
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';

import './Header.scss';

const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ delay: 0.5, duration: 0.75 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Bienvenue sur mon</p>
              <h1 className="head-text">Portfolio</h1>
            </div>
          </div>
        </div>
      </motion.div>
      <div className="app__header-img">
        <motion.img
          whileInView={{ y: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.75 }}
          src={images.devops}
          alt="profile_bg"
          className="app__header-img-devops"
        />
        {[ // List logos inside bubble
          images.python, images.react, images.npm,
          images.pytest, images.eslint, images.gitlab_ci,
          images.terraform, images.aws, images.docker,
          images.kubernetes, images.prometheus, images.grafana,
        ].map((circle, index) => (
          <motion.div
            whileInView={{
              y: [-10, 0],
              opacity: [0.85, 0.85],
              transition: {
                repeat: Infinity,
                repeatType: 'reverse'
              }
            }}
            whileHover={{ scale: 1.2 }}
            className="app__flex"
            key={`circle-${index}`}
          >
            <img
              src={circle}
              alt="circle"
            />
          </motion.div>
        ))}
      </div>
      <div className="app__header-bottom">
        <motion.div
          whileInView={{
            y: [20, 0],
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
            }
          }}
        >
          <p>Faites défiler vers le bas</p>
          <span>▼</span>
          <span>▼</span>
          <span>▼</span>
        </motion.div>
      </div>
    </div>
  )
}

export default AppWrap(Header, 'home');